/**
 *
 * Custom function
 *
 */


(function ($) {

    /*
     *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
     */

    function allClick() {
        var clickbox = $('.allclick');
        clickbox.each(function () {
            var $this = $(this);
            $this.css('cursor', 'pointer');
            $this.find('a').click(function (event) {
                event.preventDefault();
            });

            if ($this.find('a').attr('target') == '_blank') {
                $this.click(function () {
                    window.open($('a', this).attr('href'));
                });
            } else {
                $this.click(function () {
                    window.location.href = $('a', this).attr('href');
                });
            }
        });
    }

    /*
     *	Caricamento ajax delle immagini dal marcatore [data-loadimg] e dei background dal marcatore [data-loadbackground]
     */

    function loadAsynchronousImage() {

        $('*[data-loadimg]').each(function () {
            var box = $(this),
                src = box.attr('data-loadimg'),
                alt = '',
                img = new Image();

            if (box.attr('data-alt')) {
                alt = box.attr('data-alt');
            }

            $(img).load(function () {
                box.append('<img src="' + src + '" alt="' + alt + '" width="' + img.width + '" height="' + img.height + '">');
                $('img', box).hide().fadeIn();

            }).attr('src', src);
        });

        $('*[data-loadbackground]').each(function () {
            var box = $(this),
                src = box.attr('data-loadbackground');

            box.css({
                'background-image': 'url(' + src + ')',
                'background-repeat': 'no-repeat',
                'background-position': 'top center'
            });

            if (box.has(['data-position'])) {
                box.css({
                    'background-position': box.attr('data-position'),
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            } else {
                box.css({
                    'background-position': 'top center',
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            }

            if (box.attr('data-repeat') == 'repeat') {
                box.css({
                    'background-repeat': 'repeat'
                });
            } else {
                box.css({
                    'background-repeat': 'no-repeat'
                });
            }
        });
    }


    /*
     *	Funzione per la gestione dei bottoni "condividi" legati ai social
     */

    function socialOpen() {
        $('[data-socialurl]').each(function () {
            var $this = $(this),
                url = $this.attr('data-socialurl');

            $this.click(function () {
                window.open(url, '', 'width=500, height=500');
            });

        });
    }

    function trackAdwordsCampaign() {
        //$('body').append('<img height="1" width="1" style="border-style:none;" alt="" src=""/>');
    }


    /*
     *	Funzione per l'apertura di una finestra modale tramite cookie
     */

    function openModal() {
        if ($('#onloadmodal').length) {
            if (!$.cookie("openmodal")) {
                $('#onloadmodal').modal();
                $.cookie("openmodal", 'no', {
                    path: '/'
                });
            }
        }
    }



    /*
     *	Funzione per l'apertura di un iframe all'interno di una finestra modale
     */

    function iframeModalOpen() {
        $('.videoModal').each(function () {
            // impostiamo gli attributi da aggiungere all'iframe es: data-src andrà ad impostare l'url dell'iframe
            $('.modalButton').on('click', function (e) {
                var src = $(this).attr('data-src');
                var width = $(this).attr('data-width') || 640; // larghezza dell'iframe se non impostato usa 640
                var height = $(this).attr('data-height') || 360; // altezza dell'iframe se non impostato usa 360

                var allowfullscreen = $(this).attr('data-video-fullscreen'); // impostiamo sul bottone l'attributo allowfullscreen se è un video per permettere di passare alla modalità tutto schermo

                // stampiamo i nostri dati nell'iframe
                $(".videoModal iframe").attr({
                    'src': src,
                    'height': height,
                    'width': width,
                    'allowfullscreen': ''
                });
            });

            // se si chiude la modale resettiamo i dati dell'iframe per impedire ad un video di continuare a riprodursi anche quando la modale è chiusa
            $(this).on('hidden.bs.modal', function () {
                $(this).find('iframe').html("");
                $(this).find('iframe').attr("src", "");
            });
        });
    }

    /*
     *	Funzione per la formattazione delle tabelle con classe listino
     *	tramite le classi di bootstrap
     */

    function responsiveTable() {

        $('.entry-content table').each(function () {
            var $this = $(this);
            $this.addClass('table');
            $this.wrap('<div class="table-responsive"></div>');
        });

        if ($(window).width() <= 767) {

            $('#primary .table-responsive').prepend(
                '<span class="angle-scroll-left">' +
                '<i class="icon icon-edt-arrow-right-6 angle-scroll-left__animation"></i>' +
                '</span>'
            );

            $('#primary .table-responsive').on('scroll', function (event) {
                var angleScrollLeft = $('.angle-scroll-left');

                if ($(this).scrollLeft() >= 1) {
                    angleScrollLeft.addClass('fade-out');
                } else {
                    angleScrollLeft.removeClass('fade-out');
                }
            });

            $('#primary').find('.table-responsive').each(function () {
                var table = $(this);
                $('.angle-scroll-left').click(function (event) {
                    table.animate({
                        scrollLeft: '+=60'
                    }, 200);
                });
            });
        }
    }

    /*
     *	Funzione per l'inizializzazione della mappa
     */
    function initializeGmap() {
        $('*[data-location]').each(function () {
            var map = $(this),
                address = map.attr('data-location');
            map.setGMap({
                address: address
            });
        });
    }


    /**
     * Funzione per l'attivazione del menu responsive
     */

    function responsiveMenu(viewportSize) {
        $('.main-navigation').find('a[href="#"]').each(function () {
            $(this).click(function (event) {
                event.preventDefault();
            });
        });

        if ($(window).width() <= viewportSize) { // se minore o uguale a 1199px di default
            var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");
            var fixedMenu = $('#js-fixed-menu')[0];

            if (!fixedMenu) return;

            var stickyHeader = new Waypoint.Sticky({
                element: fixedMenu
            });

            $('.main-navigation').find('a[href="#"]').each(function () {
                $(this).click(function (event) {
                    if (!$(this).next().hasClass('open')) {
                        $('ul.sub-menu').removeClass('open');
                        $(this).next().addClass("open");
                    } else {
                        $(this).next().removeClass("open");
                    }
                });
            });

            $("#js-menu-offcanvas-button-open").click(function () {
                $('body').css("overflow", 'hidden');
                menuPrimaryOffcanvas.addClass("offcanvas-open");
            });

            $("#js-menu-offcanvas-button-close").click(function () {
                $('body').css("overflow", '');
                menuPrimaryOffcanvas.removeClass("offcanvas-open");
            });
        } else {
            var stickyHeader = new Waypoint.Sticky({
                element: $('#js-header')[0],
                // offset: -250 // l'offset è utile se non vuoi far partire subito fisso il menu
            });
        }
    }


    /**
     * Funzione per nascondere e visualizzare la testata allo scroll della pagina
     */

    function scrollHeader() {
        $('#js-header').scrollHide({
            scrollDelta: 10,
            scrollOffset: 130,
            scrollClass: 'scrh-header',
            scrollClassHide: 'scrh-hide',
            onScroll: function (context, msg, currentTop) {
                if (currentTop !== 0) {
                    if (!$('body').hasClass('is-mobile')) $(context).addClass('reduced');
                    $('.menu-offcanvas').removeClass('offcanvas-open');
                }

                if (currentTop === 0) {
                    if (!$('body').hasClass('is-mobile')) $(context).removeClass('reduced');
                    $('.menu-offcanvas').removeClass('offcanvas-open');
                }

            }
        });
    }


    /**
     * Funzione per gestire il fake form allo scroll della pagina
     */

    function scrollFake() {
        var formFake = $('.fake-form'),
            button = $('#button-side');

        if ($(window).width() > 767 && formFake.length > 0) { // se esiste il form e non siamo a livello mobile (smartphones)

            if ($('body').hasClass('single')) { // se la pagina è singola (camera o offerta)
                formFake.hide();
                button.css('right', 0);
            }

            if ($('body').hasClass('no-slideshow')) { // se la pagina non ha lo slideshow
                button.css('right', -240);
            } else {
                var position = formFake.offset().top + formFake.outerHeight();

                $(window).scroll(function () {
                    if ($(this).scrollTop() >= position) {
                        button.css('right', 0);
                    } else {
                        button.css('right', -340);
                    }
                });
            }

        }
    }


    /*
     *  Funzioni per PhotoSwiper
     */
    function addImageSizeForPhotoSwiper() {
        var thumbs = $('img[data-big-size]');
        thumbs.each(function (index) {
            if ($(this).parent('a').length > 0) {
                var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
                if (isImageLink) {
                    big_size = $(this).data('big-size');
                    link = $(this).parent('a');
                    link.attr('data-size', big_size);
                    link.wrap('<div class="single-image"></div>');
                }
            }
        });
    }

    function initPhotoSwiper(selector) {
        var galleryItems = $(selector);
        var pswpItems = [];
        galleryItems.each(function (index) {
            var dimensions = $(this).find('a').data('size').split('x');
            var width = parseInt(dimensions[0], 10);
            var height = parseInt(dimensions[1], 10);

            myImage = {
                src: $(this).find('a').attr('href'),
                w: width,
                h: height,
                msrc: $(this).find('img').attr('src')
            };

            pswpItems.push(myImage);
        });


        galleryItems.each(function (index) {
            $(this).click(function (evt) {
                evt.preventDefault();
                openPhotoSwipe(pswpItems, index);
            });
        });
    }

    function openPhotoSwipe(galleryItems, index) {
        var options = {
            index: index
        };
        var pswpElement = $('.pswp')[0];

        var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
        gallery.init();
    }





    function smoothScroll() {
        $("a[href*='#']").not("[href='#'],[data-toggle]").click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {

                    if (target.attr('data-paddingTopScroll')) {
                        var adjust = target.attr('data-paddingTopScroll');
                    } else {
                        var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
                    }

                    $('html, body').animate({
                        scrollTop: target.offset().top - adjust
                    }, 1000);

                    return false;
                }
            }
        });
    }

    function startOwl(selector) {
        $(selector).each(function (arguments) {
            var owl = $(this);
            var params = {
                nav: owl.data('owl-navigation'),
                navSpeed: owl.data('owl-slide-speed'),
                autoplay: owl.data('owl-autoplay'),
                dots: owl.data('owl-dots'),
                dotsSpeed: owl.data('owl-dots-speed'),
                loop: owl.data('owl-loop'),
                autoHeight: false,
                lazyLoad: true,
                navText: [
                    "<i class='icon-edt-arrow-left-2'></i>",
                    "<i class='icon-edt-arrow-right-2'></i>"
                ]
            };

            if (owl.data('owl-single-item')) {
                params['items'] = 1;
            } else {
                params['responsive'] = {
                    0: {
                        items: owl.data('owl-items-xs'),
                    },
                    768: {
                        items: owl.data('owl-items-sm'),
                    },
                    992: {
                        items: owl.data('owl-items-md'),
                    },
                    1200: {
                        items: owl.data('owl-items-lg'),
                    },
                    1440: {
                        items: owl.data('owl-items-xl'),
                    }
                };
            }

            if (jQuery(window).width() > 1024 && $(this).parent().attr('class') == 'header-slideshow') {
                params['autoWidth'] = true;
            }

            owl.owlCarousel(params);

            if (owl.data('owl-prev')) {
                $(owl.data('owl-prev')).click(function () {
                    owl.trigger('prev.owl.carousel');
                });
            }
            if (owl.data('owl-next')) {
                $(owl.data('owl-next')).click(function () {
                    owl.trigger('next.owl.carousel');
                });
            }
        });
    }


    function slideMobile() {
        if (!jQuery('body').hasClass('is-mobile')) return;

        var imgItem = jQuery('.header-slideshow .image img');

        if (jQuery(window).width() > 767) {

            for (i = 0; i < imgItem.length; i++) {
                jQuery(imgItem[i]).attr('src', mobileSize.tablet[i])
            }
        } else {
            for (i = 0; i < imgItem.length; i++) {
                jQuery(imgItem[i]).attr('src', mobileSize.mobile[i])
            }
        }
    }

    /*****************************************************************************************/

    function toConsole(string) {
        if ((typeof window.console == "undefined")) {
            alert(string);
        } else console.log(string);
    }

    function $j(argument) {
        var result = jQuery(argument);
        if (result.length === 0) return null;
        else return result;
    }

    function translateCountdown(langCountdown) {

        countdown.resetLabels();

        if (langCountdown == 'it') {
            countdown.setLabels(
                ' millisecondo| secondo| minuto| ora| giorno| settimana| mese| anno| decade| secolo| millennio',
                ' millisecondi| secondi| minuti| ore| giorni| settimane| mesi| anni| decadi| secoli| millenni',
                ' e ',
                ' '
            );
        } else if (langCountdown == 'de') {
            countdown.setLabels(
                ' Millisekunde| zweite| Minute| jetzt| Tag| Woche| Monat| Jahr| Jahrzehnt| Jahrhundert| Jahrtausend',
                ' Millisekunden| Sekunden| Minuten| Stunden| Tage| Wochen| Monate| Alter| Jahrzehnte| Jahrhunderte| Jahrtausende',
                ' und ',
                ' '
            );
        } else if (langCountdown == 'fr') {
            countdown.setLabels(
                ' milliseconde| deuxième| minute| maintenant| jour| semaine| mois| année| décennie| siècle| millénaire',
                ' millisecondes| secondes| minutes| heures| journées| semaines| mois| âge| décennies| siècles| millénaires',
                ' et ',
                ' '
            );
        }

    }

    function edtCountdown() {

        jQuery(".edt_countdown_class").find('.item').each(function () {

            var dataInizio = "";
            var questo = jQuery(this);
            jQuery.post(
                url.ajax_url, {
                    'action': 'edita_ajax_get_start_countdown_date',
                    'offerId': questo.data('offerid'),
                },

                function (response) {
                    setInterval(function () {
                        stampaCountdown(questo, response);
                    }, 3600);
                }
            );
        });
    }

    function stampaCountdown(item, response) {
        jQuery(item).find('.pageTimer').empty().append(
            moment().countdown(response, countdown.DAYS | countdown.HOURS).toString()
        );
    }

    function closePhotoswipe() {

        $('.pswp__button--close').click(function () {
            if ($('.pswp').hasClass('pswp--open')) {
                $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
            }
        });

        $(window).scroll(function () {
            if ($('.pswp').hasClass('pswp--open')) {
                $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
            }
        });
    }

    /*
     * Funzione per utilizzare MixItUp da select
     */

    function filterElements() {

        var filterSelect = '.select-filters',
            container = '.mix-filter-support';

        if ($(container).length) {
            var mixer = mixitup(container);

            $(filterSelect).on('change', function () {
                mixer.filter(this.value);
            });
        }
    }

    /**
     * Questo oggetto ci serve a verificare il dispositivo utilizzato dall'utente,
     * la i. nell'espressione regolare sta ad indicare una ricerca case-insensitive,
     * il metodo nativo test() serve a verificare la presenza della parola nell'espressione regolare,
     * ritorna un boleano.
     */
    var mapsMobileUrl = {
        isAndroid: function () {
            return /(android)/i.test(navigator.userAgent);
        },
        isIos: function () {
            return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
        }
    };


    function injectorLinkMaps() {
        var buttonMaps = $('#js-fixed-menu').find('.navigator');

        if (mapsMobileUrl.isAndroid()) buttonMaps.attr('href', phpMapsMobileUrl.android);

        if (mapsMobileUrl.isIos()) buttonMaps.attr('href', phpMapsMobileUrl.ios);

        if (!mapsMobileUrl.isAndroid() && !mapsMobileUrl.isIos()) buttonMaps.hide();
    }


    /* note(@duncanmid): equal heights */

    $('.camere-article').equalHeights();
    // 	$('.in-evidenza-col').equalHeights();
    $('.section-contenuto-aggiuntivo h3').equalHeights();
    $('.pacchetto-inner').equalHeights();


    var resizeEnd;

    jQuery(window).on('resize', function () {

        clearTimeout(resizeEnd);

        resizeEnd = setTimeout(function () {
            jQuery(window).trigger('resizeEnd');
        }, 500);

    });


    jQuery(window).on('resizeEnd', function () {
        setPageGallerySlider();
    })


    function setPageGallerySlider() {

        var width = jQuery(window).width();

        var slideCount = 4;

        if (width > 767) {

            // 3cols...
            slideCount = 4;


        } else if (width > 479) {

            slideCount = 2;

        } else {

            slideCount = 1;
        }

        jQuery('.page-gallery-slideshow').cycle('destroy');

        jQuery('.page-gallery-slideshow').cycle({
            fx: 'carousel',
            timeout: 0,
            speed: 600,
            carouselVisible: slideCount,
            carouselFluid: true,
            slides: '.slide',
            prev: '#slider-prev',
            next: '#slider-next',
            autoHeight: 'calc'
        });

    }
    /**
     * Funzione per l'attivazione del form
     */
    function toggleForm(viewportSize) {
        var formWrapper = $(".efb-form-wrapper");
        var formContatti = $(".edita-form-builder");
        var formFake = $('#form-placeholder');
        var buttonClose = $('#button-close');
        var formContattiH = formContatti.find('.efb-fieldset').height() + 40;
        var backDrop = '<div id="backDrop"></div>';

        var formInnerClick = function (event) {
            formWrapper.addClass("clicked");
            formContatti.css({
                "min-height": '100%',
                "overflow": 'initial'
            }).animate({
                height: formContattiH
            }, 500);
            buttonClose.css('opacity', '1');
            formFake.addClass("fake-close");

            $('body').prepend(backDrop);

            $(this).off();
        };

        $('.efb-form-inner').click(formInnerClick);

        $(".closePopupForm").click(function () {
            formWrapper.removeClass("clicked");
            formContatti.css({
                "min-height": 0,
                "overflow": 'hidden'
            }).animate({
                height: '0px'
            }, 500);
            buttonClose.css('opacity', '0');
            formFake.removeClass("fake-close");

            $('#backDrop').remove();
        });
    }

	// inizializzo le date del fake-form in javascript
	// per evitare la cache lato server
	function initFakeDate() {

		try {
			var now = new Date();
			var dataIn = new Date();

			// se è stata indicata una data di chiusura nelle opzioni del plugin "edita-form-builder"
			if( typeof( efb_config.checkin ) != "undefined" && typeof( efb_config.checkout ) != "undefined" ) {

				var openDate = efb_config.checkin.split('-');
				openDate = new Date( openDate[2], openDate[1] - 1, openDate[0] );

				var closeDate = efb_config.checkout.split('-');
				closeDate = new Date( closeDate[2], closeDate[1] - 1, closeDate[0] );

				// se la data di chiusura è passata, aggiorno le date al primo anno utile
				if( dataIn.getTime() > closeDate.getTime()) {
					dataIn.setTime( openDate.getTime());
					dataIn.setFullYear( now.getFullYear());

					if( dataIn.getTime() < now.getTime()) {
						dataIn.setTime( now.getTime());
					}
				}

				// se la data di apertura è passata, metto il giorno di apertura
				if( dataIn.getTime() < openDate.getTime()) {
					dataIn.setTime( openDate.getTime());
				}
				
				// se la data di apertura è già passata metto la data odierna
				if( dataIn.getTime() < now.getTime()) {
					dataIn.setTime( now.getTime());
				}
			}

			var tomorrow = new Date( dataIn.getTime() + 86400000 );

			console.log( openDate, closeDate);

			document.querySelector('.fake-arrival .date').textContent = ("0" + (dataIn.getDate())).slice(-2) + "/" + ("0" + ( dataIn.getMonth() + 1 )).slice(-2) + "/" + dataIn.getFullYear();
			document.querySelector('.fake-departure .date').textContent = ("0" + (tomorrow.getDate())).slice(-2) + "/" + ("0" + ( tomorrow.getMonth() + 1 )).slice(-2) + "/" + tomorrow.getFullYear();
		} catch(e) { console.log('[initFakeDate]', e ); }
	}

    /**
     * Funzione per l'attivazione del form
     */
    /*
        function toggleForm( viewportSize ) {
            var formWrapper = $( ".efb-form-wrapper" );
            var formContatti = $( ".edita-form-builder" );
            var formFake = 		$( '#form-placeholder' );
            var buttonClose = $('#button-close');
            var infoBooking = $('.container-info-booking');
            var infoBookingH = infoBooking.height();
            var formContattiH = formContatti.find('.row').height();
            var layerMenu = $('.layer-menu');
            //var tl = new TimelineMax();

            infoBooking.height(0);

            var formInnerClick = function( event ) {            

            //    tl.to(layerMenu, 0.3, { autoAlpha: 0.5, ease: Power2.easeOut });
                
                layerMenu.css({ 'z-index': 50 });
                
                formFake.addClass( "fake-open" );
                
                formContatti.css( { "min-height": '100%', "overflow": 'initial' } ).animate( { height: formContattiH }, 500);

                buttonClose.css( 'opacity', '1' ); 

                $('.efb-popup-container').css( 'opacity', '0' );
                
                setTimeout( function(){
                    infoBooking.css( { display: 'block' } ).animate( { height: infoBookingH }, 500 );
                }, 100 );
                            
                formFake.addClass( "fake-close" );

                $(this).off();
            };
            
            $('.efb-form-inner').click( formInnerClick );
                    
            $( ".closePopupForm" ).click(function() {

                layerMenu.css({ 'z-index': 300 });

                formContatti.css( { "min-height": 0, "overflow": 'hidden' });
                
                formContatti.animate({
                    height: '0px'
                }, 500, 'linear', function() {
                    formWrapper.removeClass("fake-open");
                    formFake.removeClass("fake-close");
                }); 

                infoBooking.animate({
                    height: '0px'
                }, 500, 'linear', function() {
                    $(this).css( { display: 'none' } )
                });
                
                buttonClose.css( 'opacity', '0' );

            //    TweenMax.to(layerMenu, 0.3, { autoAlpha: 0, ease: Power2.easeOut });

                setTimeout( function(){
                    $('.efb-popup-container').css( 'opacity', '1' );

                    $('.efb-form-inner').click( formInnerClick );
                }, 300 );
            });	
            
        }
    */


    // function slideMobile() {
    //     if ( !$('body').hasClass('is-mobile') ) return;

    //     if ( $( window ).width() > 767 ) {
    //         $('.header-slideshow img').attr('src', mobileSize.tablet[0])
    //     } else {
    //         $('.header-slideshow img').attr('src', mobileSize.mobile[0])
    //     }
    // }

    $j(document).ready(function () {

        allClick();
        responsiveTable();
        addImageSizeForPhotoSwiper();
        iframeModalOpen();
        responsiveMenu(1199);
        scrollHeader();
        filterElements();
        injectorLinkMaps();
        toggleForm(1199);

        setPageGallerySlider();

        initPhotoSwiper(".gallery-image");


        $('.feedback-description').collapser({
            mode: 'chars',
            truncate: 360,
            showText: '[ + ]',
            hideText: '[ - ]',
            controlBtn: 'opener',
        });

        //startOwl('.owl-carousel');
        smoothScroll();
        slideMobile();

        closePhotoswipe();

        var mixFilter = $('.mix-filter-support');
        if (mixFilter.length) mixitup(mixFilter);


        if (typeof lang && lang != 'en') {
            translateCountdown(lang);
        }

        edtCountdown();


        $('#video-block').click(function () {

            $("#video-modal").modal("show");
        });

        // Fix for Apple devices

        var ua = navigator.userAgent,
            iOS = /iPad|iPhone|iPod/.test(ua),
            iOS11 = /OS 11_0_1|OS 11_0_2|OS 11_0_3|OS 11_1|OS 11_1_1|OS 11_1_2|OS 11_2|OS 11_2_1/.test(ua);

        // ios 11 bug caret position
        if (iOS && iOS11) {
            // Add CSS class to body
            $("body").addClass("iosBugFixCaret");
        }

        /* Date Picker */

        if ($.datepicker) {
            $.datepicker.setDefaults($.datepicker.regional[lang]);
            jQuery('#arrival, #departure').datepicker({
                beforeShow: function (input) {
                    var minDate = 1;
                    if (input.id == "departure") {
                        minDate = jQuery('#arrival').datepicker("getDate");
                        minDate = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate() + 1);
                    }

                    return {
                        minDate: minDate
                    }
                }​
            });
        }

        /* booking - on change bambini */

        $('#bambini').change(function () {

            var val = $(this).val(),
                label1 = $('#ages').data('label1'),
                label2 = $('#ages').data('label2'),
                label3 = $('#ages').data('label3');

            $('#ages').empty();

            for (i = 0; i < val; i++) {

                $('#ages').append('<select name="bambino[' + i + ']"><option value="0">' + label1 + ' ' + (i + 1) + '</option><option value="1">1 ' + label2 + '</option><option value="2">2 ' + label3 + '</option><option value="3">3 ' + label3 + '</option><option value="4">4 ' + label3 + '</option><option value="5">5 ' + label3 + '</option><option value="6">6 ' + label3 + '</option><option value="7">7 ' + label3 + '</option><option value="8">8 ' + label3 + '</option><option value="9">9 ' + label3 + '</option><option value="10">10 ' + label3 + '</option><option value="11">11 ' + label3 + '</option><option value="12">12 ' + label3 + '</option></select>');
            }

        });

        /* Chiusura form modale al submit */

        $('#booking').on('submit', function () {
            $('#formModal').modal('hide');
        });

        /* Cambio messaggio di errore */

        $('#newsletter').find('input[required]').on('change invalid', function () {
            var field = $(this).get(0);
            var message = $(this).attr('title');

            field.setCustomValidity('');

            if (!field.validity.valid) {
                field.setCustomValidity(message);
            }
        });

        //scrollFake();

        $('.gform_wrapper form').each(function () {
            $(this).checkEditaForm();
        });

        $('.checkNewsletter').each(function () {
            $(this).checkEditaForm();
        });
		
		// gestione date (apertura/chiusura) fake form
		initFakeDate();

        /* Fix per modale con contenuto dinamico */

        $('#feedModal').on('hidden.bs.modal', function (e) {
            $(this).removeData('bs.modal');
            $(".modal-content", this).empty();
        });


        /*
        		$('.camere-article').click(function() {
        			
        			$('#efb-306 option').removeAttr('selected');
        			var cta = $(this).find('.camere-cta').data('tipologia');
        			
        			$('#efb-306 option').filter(function () { return $(this).html() == cta; }).attr('selected', 'selected');
        			
        			$('#richiestapreventivo-modal'). modal('show');
        		});
        */

    });


    $j(window).load(function () {
        /*
         *	Attraverso il Google Loader carico il modulo mappa
         */

        startOwl('.owl-carousel');
        scrollFake();

        if ($('body').hasClass('page-template-template-mappa')) {

            $.getScript('https://www.google.com/jsapi', function () {
                if (url.key) {
                    var map_params = 'language=' + url.lingua + '&key=' + url.key;
                } else {
                    var map_params = 'language=' + url.lingua;
                }

                google.load('maps', '3', {
                    other_params: map_params + '&libraries=places',
                    callback: function () {
                        initializeGmap();
                    }
                });
            });
        }


        var gallery = $('.gallery');

        if (gallery.length) {
            gallery.each(function (index) {

                initPhotoSwiper("#" + $(this).attr('id') + " .gallery-item");
            });
        }

        initPhotoSwiper(".entry-content .single-image");

        $('.section-form .form-group').equalHeights();
        $('.camera-services ul li').equalHeights();



        var content = jQuery('#header-booking');
        if (content.length < 1) return;

        var contentPosition = content.offset().top;

        jQuery(window).scroll(function () {


            var scrollPosition = jQuery(document).scrollTop();

            if (scrollPosition > contentPosition + 80) {

                jQuery('#fixed-cta').addClass('visible');

            } else {

                jQuery('#fixed-cta').removeClass('visible');
            }
        });



    });

    $j(window).resize(function () {



    });

})(jQuery);